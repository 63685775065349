<template>
  <b-modal
      v-model="show"
      title="Ajuda"
      size="lg"
      centered
      scrollable
      hide-footer
      @hidden="closed"
  >
    <div style="max-height:85vh">
      <div>
        <h4>Atalhos</h4>
        <ul class="shortcuts-list">
          <li
              v-for="(shortcut, index) in finalShortcuts"
              :key="index"
          >
          <span
              v-for="(key, i) in shortcut.keys"
              :key="i"
              class="shortcut-badge"
          >
            {{ key }}
          </span>
            {{ shortcut.description }}
          </li>
        </ul>
      </div>

      <hr/>

      <!-- Seção de ajuda (pesquisa + "lista" e "detalhe") -->
      <div v-if="currentHelp?.help_items?.length > 0">
        <h4>Itens de Ajuda</h4>

        <!-- Campo de pesquisa -->
        <b-form-input
            v-model="search"
            placeholder="Pesquisar..."
            class="mb-1"
        />

        <div v-if="!selectedItem">
          <ul class="help-list">
            <li
                v-for="(item, i) in filteredHelpItems"
                :key="i"
                @click="selectItem(item)"
                class="help-list-item"
            >
              <span class="help-item-icon">
              <feather-icon icon="FileIcon"/>
            </span>
              <span class="help-item-title">{{ item.title }}</span>
            </li>
          </ul>
        </div>

        <div v-else class="article-item">
          <button class="btn btn-link px-0" @click="selectedItem = null">
            ← Voltar
          </button>
          <h5>{{ selectedItem.title }}</h5>
          <hr/>


          <p v-html="selectedItem.text"/>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted, ref, watch} from 'vue'
import {useRouter} from '@core/utils/utils'
import HelpData from '@/variables/Help/HelpData' // 1) Definindo props e emits

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['close'])

const show = ref(false)

const route = useRouter()

const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform)
const ctrlKey = isMac ? '⌘' : 'Ctrl'

const search = ref('')

const selectedItem = ref(null)

onMounted(() => {
  show.value = props.show
})

watch(() => props.show, newVal => {
  show.value = newVal
})

const closed = () => {
  emit('close')
}

const currentHelp = computed(() => {
  return HelpData.find(item => item.route.includes(route.route.value.name)) || {}
})

const finalShortcuts = computed(() => {
  if (!currentHelp.value.shortcuts) return []
  return currentHelp.value.shortcuts.map(shortcut => {
    return {
      ...shortcut,
      keys: [ctrlKey, ...shortcut.keys]
    }
  })
})

const filteredHelpItems = computed(() => {
  if (!currentHelp.value.help_items) return []
  if (!search.value) return currentHelp.value.help_items

  return currentHelp.value.help_items.filter(item =>
      item.title.toLowerCase().includes(search.value.toLowerCase()) ||
      item.text.toLowerCase().includes(search.value.toLowerCase())
  )
})

const selectItem = (item) => {
  selectedItem.value = item
}
</script>

<style scoped>
.shortcuts-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.shortcuts-list li {
  margin-bottom: 8px;
}

.shortcut-badge {
  display: inline-block;
  padding: 2px 6px;
  margin-right: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 0.9rem;
  color: #333;
  font-weight: 500;
  min-width: 28px;
  text-align: center;
}


.help-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.help-list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.article-item {
  position: absolute;
  top: 0px;
  background-color: white;
  height: 100%;
  width: 99%
}

.help-list-item:hover {
  background-color: #fafafa;
}

.help-item-icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5f6368;
}

.help-item-title {
  font-weight: 500;
  color: #202124;
}


.dark-layout .shortcut-badge {
  border-color: #555;
  background-color: #444;
  color: #eee;
}

.dark-layout .help-list-item {
  border-color: #555;
}

.dark-layout .help-list-item:hover {
  background-color: #333;
}

.dark-layout .help-item-title {
  color: #fff;
}

.dark-layout .help-item-icon {
  color: #ccc;
}

.dark-layout .article-item {
  background-color: #2E3035;
  color: #fff;
}
</style>

const state = {
    configs: null,
    loaded:false
}

const getters = {
    configs: state => {
        return state.configs
    }
}

const mutations = {
    SET_CONFIGS(state, payload) {
        state.configs = payload
        state.loaded = true
    },
}

const actions = {
    getConfigs: async ({ commit, state }) => {

        if (state.loaded) {
            return Promise.resolve(state.configs)
        }
        try {
            const data = await window.http.get(`erp-configs`, { loading: false })
            if (data.error) {
                return null
            }
            commit('SET_CONFIGS', data)
            return data
        } catch (error) {
            return error
        }
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

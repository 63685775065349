const HelpData = [
    {
        route: ['atendimentos.show'],
        shortcuts: [
            {keys: ['M'], description: 'Posiciona no campo "Aplicar Macro"'},
            {keys: ['E'], description: 'Editar/cancelar novo comentário'},
        ],
        help_items:
            [
                {
                    title: 'Novo Atendimento',
                    text: 'Aqui voce preenche os dados de um novo atendimento'
                },
                {
                    title: 'Campos obrigatórios',
                    text: 'Verifique os campos Obrigatorios, eles estarão marcados em vermelho, caso nao sejem preenchidos.'
                }
            ]
    },
    {
        route: ['dashboard.geral'],
        shortcuts: [
        ],
        help_items:
            [
                {
                    title: 'Pendências por área',
                    text: 'Mostra a quantidade de tickets pendentes por área e a quantidade geral da empresa. Em cada área, abaixo do número principal, é exibida a quantidade limite esperada para uma situação normal. A partir disto, o gráfico fica amarelo.'
                },
                {
                    title: 'Campos obrigatórios',
                    text: 'Verifique os campos Obrigatorios, eles estarão marcados em vermelho, caso nao sejem preenchidos.'
                }
            ]
    },
]

export default HelpData

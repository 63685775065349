import moment from 'moment-timezone'
import {isValid as isValidCpf} from '@fnando/cpf'
import {isValid as isValidCpnj} from '@fnando/cnpj'

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{6,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?((?:\/\w+)|(?:-\w+))*\/?(?![^<]*(?:<\/\w+>|\/?>))/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}
export const dateFormatValidator = (val, args) => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  return moment(val, args[0], true).isValid()
}

export const tipoPorAreaValidator = (val, args) => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  return !!args.find(item => String(item) === String(val))
}
export const timeFormatValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  return moment(val, 'HH:mm', true).isValid()
}
export const cpfValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return false
  }
  return isValidCpf(val, true)
}
export const cnpjValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return false
  }
  return isValidCpnj(val, true)
}
export const cnpjCpfValidator = val => {
  const doc = val.replaceAll(/\D/g, '')
  if (doc === undefined || doc === null || doc.length === 0) {
    return false
  }
  if (doc.length === 14) {
    return isValidCpnj(doc, true)
  }
  if (doc.length === 11) {
    return isValidCpf(doc, true)
  }
  return false
}

<template>
  <b-form-group
      :label="label"
      :label-for="name"
  >
    <validation-provider
        v-slot="{ errors }"
        :name="label"
        :vid="name"
        :rules="rules"
    >
      <b-form-input
          :id="name"
          v-bind="$attrs"
          ref="elemInput"
          v-model="inputValue"
          :class="inputClass"
          v-maska:unmasked="mask ? mask : null"
          :type="type"
          :debounce="delay"
          :state="errors.length > 0 ? false:null"
          :placeholder="placeholder"
          :readonly="readonly"
          :disabled="disabled"
          v-on="$listeners"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>

</template>

<script>

import {BFormGroup, BFormInput} from 'bootstrap-vue'
import {ValidationProvider} from 'vee-validate'
import {min, minValue, required} from '@validations'
import {applyMask as _mask} from '@/helpers/mask'
import {vMaska} from "maska/vue";
import {Mask} from "maska";

export default {
  name: 'I2FormInput',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  directives: {
    mask: _mask,
    maska: vMaska,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    delay: {
      type: Number,
      default: 0,
    },
    inputClass: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: () => ({
    inputValue: '',
    errors: [],
    required,
    min,
    minValue,
    unmaskedValue: '',
    maskInstance: null
  }),
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      if (this.maskInstance) {
        this.$emit('input', this.maskInstance.unmasked(val))
        return
      }
      this.$emit('input', val)
    },
  },
  created() {
    this.inputValue = this.value
    if (this.mask !== '') {
      this.maskInstance = new Mask({mask: this.mask})
    }
  },
  methods: {
    focus() {
      this.$refs.elemInput.focus()
    },
  },

}
</script>

<style scoped>

</style>
